<template>
  <div>
    <v-container>
      <v-card max-width="90%" class=" mx-auto pb-2">
        <h2 class="ml-10 mb-2 pt-3" title="Best of Nigeria Charcoal, Frequently Asked Questions">
          Frequently Asked Questions
        </h2>
        <v-list-group
          v-for="(quest, id) in quests"
          :key="id"
          :color="themeColor"
          sub-group
          no-action
          prepend-icon="mdi-chevron-down"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title><h3 class=" text-wrap">{{ quest.question }}</h3></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item>
            <v-list-item-title class=" text-wrap">{{ quest.answer }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    quests: [
      {
        id: 'q1',
        question: "Where is our Warehouse Address?",
        answer: "Oro-Ago, Kwara State, Nigeria"
      },
      {
        id: 'q2',
        question: "Where is our Office Address?",
        answer: "2, Olomu Street, Jakpa road, Effurun. Delta State. Nigeria"
      },
      {
        id: 'q3',
        question: "How can we verify your company?",
        answer: "Our RC No. is 162996. You can verify online via The Nigeria Cooperate Affairs Commission (CAC) company verification search handle"
      },
      {
        id: 'q4',
        question: "How can we verify your status as an exporter?",
        answer: "Our NEPC No. is 0009730. You can verify our export status through The Nigeria Export Promotion Council (NEPC) and also through our Nigeria Bankers"
      },
      {
        id: 'q5',
        question: "What is our product range?",
        answer: "We have charcoal for BBQ, Shisha and Lump such as BBQ Charcoal, Resturant Charcoal, Shisha Charcoal."
      },
      {
        id: 'q6',
        question: "How can I ascertain your product price?",
        answer: "Our follow up response to each letter of intent will contain the product price. Product price is usually based on payment terms, packaging, quantity and specification."
      },
      {
        id: 'q7',
        question: "What's your export price?",
        answer: "FOB and CnF"
      },
      {
        id: 'q8',
        question: "What's your payment method?",
        answer: "Direct payment to our bank account, via Western Union or MoneyGram"
      },
      {
        id: 'q9',
        question: "What are your payment terms?",
        answer: "Advance payment, Document against payment, and Cash against document (CAD)"
      },
      {
        id: 'q10',
        question: "What's Our packaging?",
        answer: "10kgs, 20kgs - 25kgs in PP bags. Hand or machine sealing"
      },
      {
        id: 'q11',
        question: "Can we customize your brand?",
        answer: "Yes, send us a sample on how you want it."
      },
      {
        id: 'q12',
        question: "Do we send sample container?",
        answer: "Yes, we can arrange for the delivery of a sample container to your company to be sure of our quality"
      }
    ],
      themeColor: "#ff5722"
  }),
  name: "QuestionPage"
}
</script>
