<template>
  <div>
    <question-page></question-page>
  </div>
</template>

<script>
import QuestionPage from '../components/QuestionPage.vue'
export default {
  name: "Questions",
  components: { QuestionPage }
}
</script>

<style>

</style>